import ReactGA from 'react-ga';
import { production } from '../environment';

export const gaTrackPage = pageName => {
  if (production) {
    ReactGA.ga('set', 'page', pageName);
    ReactGA.ga('send', 'pageview');
  }
};

export const gaTrackEvent = ({ eventCategory, eventAction, eventLabel }) => {
  if (production) {
    ReactGA.event('send', 'event', eventCategory, eventAction, 'Fall Campaign');
  }
};
